.about-us-button,
.submit {
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  background: linear-gradient(to right, #d9ab73, #ceb485);
  border: none;
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: whitesmoke;
  transition: 0.25s ease-in-out;
  list-style: none;
  text-decoration: none;
  width: 40%;
  text-align: center;
}

.about-us-button:hover {
  transform: scale(1.02);
}
form {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  color: rgb(24, 24, 24);
}

form > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input,
textarea {
  border: none;
  padding: 0.75rem;
  background-color: whitesmoke;
  color: rgb(24, 24, 24);
  font-weight: 900;
  font-size: 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  outline: none;
  resize: none;
}

textarea {
  width: 100%;
  height: 5rem;
}

::-webkit-input-placeholder {
  font-weight: 500;
}

.submit {
  margin: auto;
  width: 100%;
  padding: 1rem;
  font-weight: 600;
  transition: all 0.3s;
}

.submit:hover {
  transform: scale(1.02);
}

label {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 1px;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }
  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }
  .contact-image-div {
    display: none;
  }
}
