.main {
  width: 100%;
  padding: 0px 10px;
  margin: 0px auto;
}

.greet-main {
  max-width: 1370px;
  padding: 10px 10px;
  margin: 0px auto;
  margin-top: 2rem;
  display: block;
  height: 100vh;
}

.greeting-main {
  display: flex;
  /* text-align: center; */
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.greeting-text-div {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  font-size: 70px;
  font-family: "Google Sans Bold";
  letter-spacing: 5px;
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

.button {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  margin-top: 25px;
  border-radius: 5px;
  border-width: 0px;
  width: 200px;
  height: 40px;
  font-weight: bold;
  font-family: "Google Sans Regular";
  font-size: 17px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 15rem;
  background-color: transparent;
  transition: margin background-color 0.5s ease-out 100ms;
  transition: 0.5s ease-out 100ms;
}

.button:hover {
  background-color: rgb(0, 0, 0, 1);
  margin: 25px 13rem 0 0;
}

.cv {
  place-content: left;
  margin-bottom: 50px;
}

.main-section-back {
  position: absolute;
  width: 100%;
  height: 101vh;
  object-fit: cover;
  opacity: 0.6;
  left: 0;
  top: -10px;
  /*animation: animatedBackground 3s linear infinite alternate;*/
  z-index: 0;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }

  .greeting-text-p {
    font-size: 20px;
  }

  .greet-main {
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .cv {
    place-content: center;
    margin-bottom: 10px;
  }

  .button-greeting-div {
    justify-content: space-around;
  }

  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
    margin: 0 auto;
  }

  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }

  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .button {
    margin-right: 0rem;
  }
}
