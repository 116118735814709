/* Estilos generales */
body {
  font-family: Arial, sans-serif;
}

/* Estilos para el acordeón */
details {
  transition: 0.3s ease-out;

  background: #f5f5f5;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.faq-container {
  padding: 50px;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.faq-item{
  max-width: 64rem;

}
summary {
  cursor: pointer;
  padding: 10px;
  outline: none;
  user-select: none;
  font-weight: bold;
}

details[open] summary {
  background: #d9ab73;
  color: white;
  border: 2px solid #d9ab73;
}

details:hover {
  border: 2px solid #d9ab73;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  opacity: 1;
}

/* Estilos para el texto de la respuesta */
p {
  padding: 10px;
  margin: 0;
}

details[open] p {
  display: block;
}
