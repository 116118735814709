/*SERVICE*/
.container-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Ajusta según tus necesidades */
}

@media (min-width: 1200px) {
  .square {
    flex: 0 1 calc(20% - 20px);
  }
}

/*SERVICE*/

.skills-main-div {
  display: flex;
}

.skills-header {
  font-size: 50px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.container-services-main-skills {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 82%;
  margin: auto;
}

#container-servs {
  font-family: "Google Sans Regular";
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 2rem;
  width: 32.5rem;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-bottom: 3rem;
  height: auto;
}
#container-servs > p {
  border-top: 2px solid rgba(19, 19, 20, 0.2);
  padding-top: 1rem;
  font-size: 15px;
  line-height: 25px;
  max-width: fit-content;
  color: rgba(64, 64, 67, 0.632);
}

#container-servs > div {
  display: flex;
  width: auto;
}

#container-servs > div > img {
  object-fit: contain;
  width: 7rem;
  margin-right: 1.5rem;
}

#container-servs > div > div {
  font-size: 1.5rem;
}

#container-servs > div > div > h4 {
  font-weight: 800;
  font-size: 1.75rem;
  color: rgba(0, 0, 0, 0.805);
  margin: 0px;
  margin-bottom: 1rem;
}

#container-servs > div > div > * {
  color: black;
  font-weight: bold;
  margin: 0px;
}

/* Article Who */
.pretitle {
  width: max-content !important;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  letter-spacing: 0.1rem;
  color: #5dff57;
  background: linear-gradient(
    247deg,
    rgba(126, 240, 92, 0.3) 0%,
    rgba(37, 220, 190, 0.4) 45%,
    rgba(120, 231, 56, 0.5) 100%,
    rgba(37, 132, 220, 0.6) 100%
  );
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: -1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.article-who {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  width: 90%;
}

.article-who > h2 {
  width: max-content;
  align-items: center;
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  color: #5dff57;
  background: linear-gradient(
    247deg,
    rgba(126, 240, 92, 0.3) 0%,
    rgba(37, 220, 190, 0.4) 45%,
    rgba(120, 231, 56, 0.5) 100%,
    rgba(37, 132, 220, 0.6) 100%
  );
  padding: 0 1rem;
  margin-top: 0rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.article-who > h1 {
  margin-top: 0;
  font-weight: 900;
  color: #0d0d0eb8;
  text-align: left;
  line-height: 3rem;
  margin-bottom: 0.5rem;
}

.article-who > h4 {
  color: #080809b8;
  font-size: 1.5rem;
}

.article-who > p {
  font-size: 1.3rem;
  font-weight: 900;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #37373b;
  line-height: 2.5;
}

/* Fin article who */
.skills-text {
  font-size: 20px;
}

.skills-text-div {
  padding-top: 80px;
  font-family: "Google Sans Regular";
}

.skills-main-div {
  display: flex;
  width: 85vw;
  margin: auto;
}

.skills-main-div > * {
  flex: 1;
}

.skills-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 100px;
}

.skills-heading {
  font-size: 45px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}

/* ^Proyectos */
.project-div {
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #37373b;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.project-div h1 {
  font-size: 30px;
  font-weight: 700;
  padding: 2rem;
  padding-bottom: 0;
  margin: 0px;
}

.project-div h2 {
  font-size: 22px;
  padding: 2rem;
  padding-top: 0;
  font-weight: 400;
}

.project-div div.imgdiv {
  position: relative;
}

.project-div div img {
  width: 15rem;
  top: -80px;
  right: 70px;
  position: absolute;
  rotate: -5deg;
  border-radius: 10px;
  transition: all 0.35s;
}

.project-div:hover div img {
  width: 17rem;
  top: -140px;
  right: 110px;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }

  .skills-header {
    font-size: 50px;
  }

  .project-div div img {
    width: 12rem;
    top: 0px;
    right: 0px;
    position: relative;
    rotate: -0deg;
    border-radius: 10px !important;
    transition: all 0.35s;
    padding: 1rem;
  }

  .project-div:hover div img {
    width: 12rem;
    top: 0px;
    right: 20px;
  }
  .who-are-we {
    flex-direction: column;
    margin: auto;
  }
  .who-are-we > div {
    margin: auto;
  }
}

@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }

  .skills-header {
    font-size: 30px;
  }

  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }

  .skills-main-div {
    flex-direction: column;
  }

  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }

  .skills-text {
    font-size: 16px;
  }

  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }

  .skills-image-div {
    order: 2;
  }

  #container-servs > p {
    font-size: 15px;
  }

  #container-servs > div > img {
    width: 5rem;
    object-fit: contain;
  }

  #container-servs > div > div {
    font-size: 1.2rem;
  }

  #container-servs > div > div > h4 {
    font-size: 1.2rem;
  }

  #container-servs > div > div > * {
  }
  .project-div {
    flex-direction: column;
  }

  .project-div div img {
    width: 13rem;
    top: 0px;
    right: 0px;
    position: relative;
    rotate: -0deg;
    border-radius: 10px !important;
    transition: all 0.35s;
    padding: 1rem;
  }
  .project-div * {
    font-size: 25px;
  }
  .project-div div h1 {
    font-size: 25px;
    margin-bottom: -5px;
  }
  .project-div div h2 {
    font-size: 18px;
    margin-bottom: -10px;
  }
  .project-div:hover div img {
    width: 13rem;
    top: 0px;
    right: 0px;
  }
}
