.main-container-team {
  cursor: "pointer";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  margin: auto;
}
.container-integrant {
  background-color: white;
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.805);
  margin: auto;
  margin-bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.container-integrant > h3 {
  font-size: 17px;
  font-weight: 500;
  margin: 0px;
}
.container-integrant > h2 {
  font-size: 25px;
  margin-bottom: 0.5rem;
}
.container-img-integrant {
  border-radius: 10px;
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 20rem;
}
.container-img-integrant img {
  width: 100%;
}
.container-socials-integrant {
  display: flex;
}
