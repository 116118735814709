.bgcolor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0)
    ),
    url("../../assests/images/1.png");
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  animation-name: animation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 1110;
  }
}
