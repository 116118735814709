button {
  display: flex;
  /* align-items: center; */
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  padding: 1em 2em 1em 1.6em;
  color: white;
  background: linear-gradient(to right, #d9ab73, #ceb485);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 16px;
  margin: 2.5rem;
}

button svg {
  margin-right: 3px;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

button span {
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

button:hover svg {
  transform: translateX(5px) rotate(90deg);
}

button:hover span {
  transform: translateX(7px);
}
