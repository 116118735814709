.cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e72a68;
  color: #fff;
  padding: 1rem;
  text-align: center;
  z-index: 3;
  padding-top: 2rem;
}

.cookie-policy button {
  background-color: #fff;
  color: #e72a68;
  border: none;
  cursor: pointer;
  margin: 0.5rem;
  border-radius: 5px;
}
